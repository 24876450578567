<style scoped>
/* .card-body >>> table > tbody > tr > td {
    cursor: pointer;
}

input[type=texte] {
    width: 100%;
    padding: 12px 20px;
    margin: 4px 0;
    box-sizing: border-box;
    border: none;
    background-color: white;
    color: white;
} */
.accordion .card-header {
	background-color: white;
}
.accordion .card-header button {
	font-weight: bold;
	color: #323635;
}
.accordion .card-body span {
	display: block;
	max-width: 80ch;
}
.card-tip {
	border-left-color: #007949;
	border-left-width: 6px;
	max-width: 80ch;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row>
			<b-col cols="12" xl="12">
				<transition name="slide">
					<div>
						<b-row class="mb-5">
							<b-col cols="12" md="6">
								<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
									{{ FormMSG(10, 'Tips of the day') }}
								</h1>
							</b-col>
							<b-col cols="12" md="6">
								<b-form-group label-cols="0" label-align-sm="left" label-size="sm" class="mb-0 mt-2">
									<b-input-group size="sm">
										<b-form-input
											id="filterInput"
											v-model="filter"
											type="search"
											class="form-control"
											:placeholder="FormMSG(25, 'Type to Search')"
										/>
										<b-input-group-append>
											<b-button :disabled="!filter" @click="filter = ''">
												<i class="fa fa-times" aria-hidden="true" />
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<div :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
							<b-tabs
								cards
								active-nav-item-class="font-weight-bold"
								active-tab-class="font-weight-bold"
								v-model="tabs.activeTabs"
								@activate-tab="(n, p, e) => onTabClicked(n)"
							>
								<b-tab v-if="Tips && !isFilmSingleUserFree && !isFilmSingleUser" :title="FormMSG(24, 'Tip of the day list')">
									<b-row v-if="Tips.length > 0">
										<b-col v-for="(item, index) in tipsFiltered" cols="12" :key="index">
											<b-card class="mx-auto my-4 shadow card-tip">
												<b-card-text>
													<div class="mt-2 small">
														<span class="text-primary">{{ item.categoryName }}</span>
													</div>
													<div v-html="item.description" />
												</b-card-text>
											</b-card>
										</b-col>
									</b-row>
									<b-row v-else>
										<b-col cols="12">
											<div class="empty-state">
												{{ FormMSG(5528, 'Currently you have no tip of the day') }}
											</div>
										</b-col>
									</b-row>
									<loading :active.sync="isLoading" :is-full-page="true" />
								</b-tab>
								<b-tab v-if="showTipOfDay && $screen.width >= 576" :title="FormMSG(350, 'Your tip of day')">
									<Tipofday />
								</b-tab>
								<!--     TABLE OF  TIP OF THE DAYS TGS-->
								<b-tab v-if="TgsTips" :title="FormMSG(35, 'TheGreenShot tip of the day list')">
									<b-row v-if="TgsTips.length > 0">
										<b-col v-for="(item, index) in tgsTipsFiltered" cols="12" :key="index">
											<b-card class="mx-auto my-4 shadow card-tip">
												<b-card-text>
													<div class="mt-2 small">
														<span class="text-primary">{{ item.categoryName }}</span>
													</div>
													<div v-html="item.description" />
												</b-card-text>
											</b-card>
										</b-col>
									</b-row>
									<b-row v-else>
										<b-col>
											<div class="empty-state">
												{{ FormMSG(5528, 'Currently you have no TGS tip of the day') }}
											</div>
										</b-col>
									</b-row>
								</b-tab>
							</b-tabs>
						</div>
					</div>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import { GetTipsOfDay, GetTgsTipsOfDay } from '@/cruds/tipofday.crud';
import { store } from '@/store';
import Tipofday from './tipofday.vue';
import activeTabMixin from '@/mixins/activeTab.mixin';

export default {
	name: 'MyTipOfDay',

	components: {
		Loading,
		Tipofday
	},
	mixins: [languageMessages, activeTabMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},

	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			Tips: [],
			TgsTips: [],
			currentPage: 1,
			currentIdx: 0,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			stopPropagation: '',
			nativeEvent: '',
			selectMode: 'single',
			selectedColor: 'primary',
			passwordInput: '',
			passwordState: null,
			useDigElecSign: false
		};
	},

	computed: {
		showTipOfDay() {
			return store.canManageTipOfTheDay();
		},
		tgsTipsFiltered: function () {
			var filter = this.filter.trim().toLowerCase();
			//filter="that";
			if (filter === '') return this.TgsTips;
			var ar = this.TgsTips.filter(function (s) {
				return s.description.toLowerCase().indexOf(filter) > -1 || s.categoryName.toLowerCase().indexOf(filter) > -1;
			});
			//console.log("ar:",ar);
			return ar;
		},
		tipsFiltered: function () {
			var filter = this.filter.trim().toLowerCase();
			//filter="that";
			if (filter === '') return this.Tips;
			var ar = this.Tips.filter(function (s) {
				return s.description.toLowerCase().indexOf(filter) > -1 || s.categoryName.toLowerCase().indexOf(filter) > -1;
			});
			//console.log("ar:",ar);
			return ar;
		},
		optionsForTipCategory() {
			return this.FormMenu(1016);
		},
		disableSave() {
			if (this.newTip.description != this.curTip.description || this.newTip.category != this.curTip.category) {
				return false;
			}
			return true;
		},
		tipfields() {
			return [
				{
					key: 'categoryName',
					label: this.FormMSG(21, 'Category'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(23, 'Description'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(38, 'Published by'),
					sortable: true
				},
				{
					key: 'publishedOn',
					label: this.FormMSG(39, 'Date'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7) + ' ' + value.substring(11, 16);
					},
					sortable: true
				}
			];
		},
		tgsTipfields() {
			return [
				{
					key: 'categoryName',
					label: this.FormMSG(21, 'Category'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(23, 'Description'),
					sortable: true
				},
				{
					key: 'publishedOn',
					label: this.FormMSG(39, 'Date'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7) + ' ' + value.substring(11, 16);
					},
					sortable: true
				}
			];
		},
		isFilmSingleUserFree() {
			return store.isFilmSingleUserFree();
		},
		isFilmSingleUser() {
			return store.isFilmSingleUser();
		}
	},

	methods: {
		setPublishedDate() {
			var date =
				this.newTip.publishedOn.split('T')[0].substring(8) +
				'/' +
				this.newTip.publishedOn.split('T')[0].substring(5, 7) +
				'/' +
				this.newTip.publishedOn.split('T')[0].substring(0, 4);
			date = date + ' ' + this.newTip.publishedOn.substring(11, 13) + ':' + this.newTip.publishedOn.substring(14, 16);
			return '' + this.newTip.publisher.name + ' ' + this.newTip.publisher.firstName + '    - ' + date;
		},

		async reloadData() {
			//console.log("inreloaddata");
			this.Tips = await GetTipsOfDay();
			//      console.log("inreloaddata tips:",this.Tips);
			var curIdx = 1;
			for (var i = 0; i < this.Tips.length; i++) {
				this.Tips[i].fullName = this.Tips[i].publisher.name + ' ' + this.Tips[i].publisher.firstName;
			}
			// select element
			if (this.Tips.length > 0) {
				// first find if there is a current selection...

				this.curTip = this.Tips[0];
				this.newTip = Object.assign({}, this.curTip);
				//            console.log("reload data idx newtip:",this.newTip);
				this.clickRow(curIdx);
			}
		},

		async reloadDataTGS() {
			//console.log("inreloaddata");
			this.TgsTips = await GetTgsTipsOfDay();
			//console.log("inreloaddata tgstips:",this.TgsTips);
		},

		rowClicked(item, index) {
			// index is the index in the currently displayed window
			// copy item content into curTip structure
			//                this.$refs.myTable.clearSelected();
			//      console.log("item in rowClicked  this.$refs.myTable:",  this.$refs.myTable);
			// compute currentIdx
		},

		clickRow(idx) {
			// idx is the index in the Tips array
			this.currentPage = 1;

			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				// let myTable = this.$refs.myTable.$el,
				// 	tableBody = myTable.getElementsByTagName('tbody')[0],
				// 	tableRows = tableBody.getElementsByTagName('tr')
				// 	//tableRows[idx % this.perPage].click();
				// 	tableRows[idx].click()
			});
		}
	},
	async created() {
		this.initTabs();
		await this.reloadData();
		await this.reloadDataTGS();
	}
};
</script>
